<template>
  <div class="support">
    <div class="container">
      <div class="banner">
        <div class="auto-container">
          <h1>{{menu.title}}</h1>
          <p>
            <a href="/">首页</a>
            <Icon
              class="arrow"
              type="ios-arrow-forward"
            /><a href="/support">支持</a>
            <Icon
              class="arrow"
              type="ios-arrow-forward"
            />{{menu.title}}
          </p>
        </div>
      </div>
      <div class="auto-container">
        <div class="info">          
          <!-- 相关下载 -->
          <Row
            :gutter="30"
            class="down"
          >
            <Col
              :xl="12"
              :lg="12"
              :md="24"
              :sm="24"
              :xs="24"
              v-for="(item,index) in contlist"
              :key="index"
            ><a target="_blank" :href="item.linkUrl">
              <div class="inner-box">
                <div class="content">
                  <div class="icon-box">
                    <img
                      :src="item.files[0].o.fullPath"
                      alt=""
                    />
                  </div>
                  <div>
                    <h3>{{item.title}}</h3>
                    <div class="time">更新时间：{{item.createTime}}</div>
                    <div class="text">{{item.abst}}</div>
                  </div>
                </div>

              </div>
            </a>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import picList from "./index.js";
export default picList;
</script>

<style lang="less" scoped>
@import "./index.less";
</style>