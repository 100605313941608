import axios from "axios"
export default {
    data() {
        return {
            searchstr: {
                page: 1,
                pageSize: 10,
                searchStr: "",
                form: "font",
            },
            weburl: "https://www.rayscloud.cn/webapi",
            contlist: [],
            menu: {},
        }
    },
    methods: {
        getList() {
            if (!this.searchstr.siteId) {
                this.searchstr.siteId = localStorage.getItem("siteId"),
                    this.searchstr.menu = localStorage.getItem("menuId"),
                    this.searchstr.child = localStorage.getItem("childId"),
                    this.searchstr.childs = this.menu.serId;
            }
            axios.post(`${this.weburl}/api/Cont/list`, this.searchstr)
                .then((res) => {
                    if (res.status == 200) {
                        this.contlist = res.data.data.data;
                    }
                })
        },
        toContant(code) {
            let route = this.$router.resolve({
                path: `/supportContant?code=${code}`
            })
            window.open(route.href, '_blank');
        }
    },
    created() {
        if (localStorage.getItem("secondMenu")) {
            this.menu = JSON.parse(localStorage.getItem("secondMenu"));
        }
        if (this.$route.query.title) {
            this.menu = { title: this.$route.query.title, serId: this.$route.query.serId };
            this.searchstr.siteId = this.$route.query.siteId;
            this.searchstr.menu = this.$route.query.menuId;
            this.searchstr.child = this.$route.query.childId;
            this.searchstr.childs = this.$route.query.serId;
        }
        this.getList();
    },
}